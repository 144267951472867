.landingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;
  padding: 0 35vw;

  .errorMessage {
    margin-top: 0;
    margin-bottom: 0;
    color: red;
  }

  @media (max-width: 768px) {
    padding: 10px;
    min-height: 70vh;
  }

  .cityTextField,
  .referralTextField {
    margin-top: 10px;
  }

  .referralTextField {
    @media screen and (max-width: 500px) {
      input::placeholder {
        font-size: 0.8em;
      }
    }
  }

  .checkboxWrapper {
    position: relative;
    display: inline-block; /* To keep the checkbox inline with other content */
  }

  .errorCheckbox::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    transform: scale(1.3);
    border-radius: 4px;
    border: 1px solid red;
    box-sizing: border-box;
  }

  .landingVectorWrapper {
    text-align: center;
    margin-bottom: 1em;
    position: relative;
    cursor: pointer;

    .videoOverlay {
      position: absolute;
      height: 100%;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 1);
      z-index: 999;

      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 12px;

      svg {
        height: 40px;
        cursor: pointer;
        width: 40px;
      }
    }

    .landingVector {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }

  .landingBottom {
    display: flex;
    justify-content: center;
    padding: 1em;

    @media (max-width: 500px) {
      padding: 0 1em;
      margin: 1em 0;
    }

    span {
      margin: 30px;

      @media (max-width: 500px) {
        margin: 10px;
      }
    }
  }

  .acceptPayment {
    margin: 1em 0;
    color: #000000;
    user-select: none;

    a {
      color: rgb(0, 0, 0);
      cursor: pointer;
    }

    input {
      transform: translateY(4px) scale(1.3);
      margin-right: 5px;
    }

    label {
      font-size: 0.9em;
    }
  }

  .landingRadios {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    label {
      font-size: 18px;
    }

    > div {
      margin: 0 10px;
    }
  }

  .submitButton {
    margin-top: 10px;
  }

  .cancel {
    text-align: center;

    span {
      color: #167afe;
      text-decoration: underline;
      cursor: pointer;
      white-space: nowrap;
      font-size: 13px;

      @media (max-width: 500px) {
        font-size: 0.7em;
      }
    }
  }

  .warningText {
    margin: 5px 0;
    font-weight: 900;

    span {
      color: #167afe;
    }
  }
}
