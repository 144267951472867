.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 99999999;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    position: relative;
    width: 30vw;
    @media(max-width: 1100px){
      width: 60vw;
    }

    @media(max-width: 500px){
      width: 80vw;
    }

    .closeButton {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      cursor: pointer;
      color: #999;
      font-size: 24px;

      &:hover {
        color: #333;
      }
    }
  }
}
