.button {
  background-color: #167afe;
  color: #fff;
  font-size: 17px;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    opacity: 0.8;
    cursor: no-drop;
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
}
