.selectContainer {
  display: flex;
  padding: 6px;
  border-radius: 8px;
  background-color: #f5f7f9;
  position: relative;

  .iconContainer {
    padding: 8px;
    background-color: #fff;
    border-radius: 6px;
    width: 20px;
    height: 20px;
    color: #167afe;
    margin-right: 8px;

    svg {
      height: 18px;
      width: 18px;
    }
  }

  .selectInput {
    border: none;
    background-color: transparent;
    outline: none;
    width: 90%;
    font-size: 18px;
    cursor: pointer;
  }

  .selectOptions {
    position: absolute;
    box-sizing: border-box;
    top: calc(100% + 10px);
    left: 0;
    background-color: #f5f7f9;
    width: 100%;
    border-radius: 8px;
    max-height: 200px;
    overflow: auto;
    cursor: pointer;
    z-index: 99;
    box-shadow: 0px 1.2px 2.2px rgba(0, 0, 0, 0.02),
      0px 2.9px 5.3px rgba(0, 0, 0, 0.028), 0px 5.5px 10px rgba(0, 0, 0, 0.035),
      0px 9.8px 17.9px rgba(0, 0, 0, 0.042),
      0px 18.4px 33.4px rgba(0, 0, 0, 0.05), 0px 44px 80px rgba(0, 0, 0, 0.07);

    .selectOption {
      padding: 10px 12px;
      border-bottom: 1px solid #fff;
      &.selectedOption {
        background-color: #167afe;
        color: #fff;
      }

      &:not(.selectedOption):hover {
        background-color: #d2d2d2;
      }
    }
  }
}
