.contactContainer {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .error {
    margin-top: 1em;
    color: rgb(255, 41, 41);
  }

  .success {
    margin-top: 1em;
    color: rgb(0, 219, 0);
  }

  @media (max-width: 768px){
    padding: 0 5vw;
    min-height: 80vh;
  }

  span {
    text-align: left;
  }

  img {
    width: 350px;
    margin: 2em 0;

    @media(max-width: 424px){
      width: 80vw;
    }
  }

  .submitButton {
    margin-top: 1em;
  }

  .contactContent {
    min-width: 30vw;
    text-align: center;

    .contactTop {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .contactEmail {
    margin-bottom: 1em;
  }
}
