.progressButton {
  position: relative;
  color: #fff;
  border: none;
  border-radius: 4px;
  min-width: 36px;
  max-width: 36px;
  overflow: hidden;
  cursor: pointer;
  &:disabled {
    background-color: #5095ff;
    cursor: no-drop;
  }

  .progressOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #167afe;
    border-radius: 4px;
  }

  svg {
    position: relative;
    z-index: 3;
    width: 70%;
  }
}
