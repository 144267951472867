.modifyForm {
  padding: 2em;

  @media (max-width: 768px) {
    padding: 0.5em;
  }

  .cancelButton {
    margin-top: 20px;
  }

  .errorMessage {
    color: rgb(255, 42, 42);
  }

  .successMessage {
    color: rgb(0, 232, 0);
  }

  .cityTextField {
    margin-top: 20px;
  }

  .landingRadios {
    display: flex;
    justify-content: center;

    > div {
      margin: 20px 20px 0 20px;
    }
  }
  .refreshIcon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loadingRefresh {
    position: relative;
    z-index: 33;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
