.footerContainer {
  display: flex;
  background-color: rgb(247, 247, 247);
  padding: 2em;
  width: 100%;
  box-sizing: border-box;

  span,
  a {
    color: black;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .footerContentTop,
    .footerContentBottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 !important;

      a {
        margin: 0.4em 0;
      }
    }

    .bull {
      display: none;
    }
  }

  .footerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    > div {
      margin: 0.5em;
    }

    span {
      margin: 0.5em;
    }

    .email {
      @media(max-width: 424px){
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .email a {
      text-decoration: underline;
      color: rgb(47, 47, 47);
    }
  }
}
