.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  .websiteName {
    font-weight: bold;
  }

  ol {
    padding-left: 20px;

    li {
      margin-bottom: 10px;
    }
  }

  p,
  span,
  li {
    font-size: 21px;
  }
}
