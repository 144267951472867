.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;

  h1 {
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  h1,
  h2 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }
}
