.textfieldContainer {
  display: flex;
  padding: 6px;
  border-radius: 8px;
  background-color: #f5f7f9;

  .prefix {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;

    span {
      font-size: 18px;
    }
  }

  .iconContainer {
    padding: 8px;
    background-color: #fff;
    border-radius: 6px;
    width: 20px;
    height: 20px;
    color: #167afe;
    margin-right: 8px;

    svg {
      height: 18px;
      width: 18px;
    }
  }

  .textfieldInput {
    border: none;
    background-color: transparent;
    outline: none;
    font-size: 18px;
    width: 90%;

    &:focus ~ .textfieldContainer {
      border: 1px solid #167afe;
    }
  }
}
